import React, { Component } from 'react';
import "../css/footer.css"

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footer-link">
					David Mueller &copy; 2020
                </div>
            </div>
        )
    }
}

export default Footer;
