import React, { Component, Text } from 'react';
import { Link } from 'react-router-dom';
import posts from './../../assets/blog-posts.json';
import notes from './../../assets/notes.json';
import "../../css/publications.css"

class BlogLanding extends Component {
    render() {
        var li = posts.posts.map((value, _) => {
			if (value.hidden == false) {
            	return (
            	    <PostItem
            	        title={value.title}
						post_ref={value.ref}
						tags={value.tags}
						post_date={value.post_date}
            	    />
            	)
			}
        });

        var li2 = notes.notes.map((value, _) => {
            return (
                <Note
                    title={value.title}
                    pdf={value.pdf}
                    latex={value.latex}
					source={value.source}
                />
            )
        });

        return (
            <div className="pub-outer">
			<h2>Blog Posts</h2>
              {li}
        	<hr className="divider"/>
			<h2>Notes</h2>
			Sets of notes that I take as I work through various fundamental
			textbooks or classes.
              {li2}
            </div>
        )
    }
}


class PubLink extends Component {
	render() {
		if (this.props.link == null) {
			return <a/>
		}
		return (
        	<a className="pub-a" href={this.props.link}>{this.props.name}</a>
		)
	}
}


class PostItem extends Component {
    render() {

		const tags = this.props.tags
  		const tagItems = tags.map((tag) =>
			<PubLink link={"#/blog"} name={tag}/>
  		);

        return (
            <div className="pub-item">
                <div className="pub-title">
                    <Link to={`/blog-post/${this.props.post_ref}`} className="pub-a">
						{this.props.title}
					</Link>
                </div>
                <div className="pub-date-text">
					Posted: {this.props.post_date}
				</div>
                <div className="pub-links">
					tags: &nbsp; 
					{tagItems}
				</div>
            </div>
        )
    }
}

class Note extends Component {
    render() {
        return (
            <div className="pub-item">
                <div className="pub-title">
                    <a className="pub-a" href={this.props.pdf}>
						{this.props.title}
					</a>
                </div>
                <div className="pub-links">
					<PubLink link={this.props.latex} name={"Latex"}/>
					<PubLink link={this.props.source} name={"Source"}/>
                </div>
            </div>
        )
    }
}

export default BlogLanding;
